// Override default variables before the import
@import '~bootstrap/scss/bootstrap.scss';
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&display=swap');
$body-bg: #fff;
* {
  // font-family: 'Montserrat', sans-serif;
}

.App {
  position: relative;
  height: 100vh;
  // padding: 1rem 2rem;
}

tbody > tr {
  cursor: pointer;
  &:hover {
    background: rgba(51, 51, 51, 0.1);
  }
}

form {
  max-width: 1040px;
  margin: 1rem auto;
}

tr {
  &.--selected {
    background: #333 !important;
    color: white;
  }
}

.form-group {
  margin: 1rem 0;
}

.custom-form-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem;
}

button {
  border-radius: 0 !important;
}

.logo-load {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    font-weight: bold;
    margin: 3rem 0 0;
  }
}

.--tri-panel {
  > div {
    &:nth-of-type(1) {
      padding: 0 3rem 0 1rem;
    }
    &:nth-of-type(2) {
      padding: 0 3rem;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-left: 1px solid rgba(0, 0, 0, 0.05);
    }
    &:nth-of-type(3) {
      padding: 0 1rem 0 3rem;
    }
  }
}

// @import './Components/Navigation/Navigation.style.scss';
@import './Components/Network/Network.style.scss';
@import './Components/Member/Member.style.scss';
@import './Components/Billing/Billing.style.scss';
@import './Components/Onboarding/Onboarding.style.scss';
@import './Components/common/Button/Button.style.scss';
