.Network {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  transform: translateY(-100%);
  z-index: 99;
  &.--visible {
    transform: translateY(0);

    display: flex;
    justify-content: center;
    align-items: center;
    background: #f0f8ff4d;
  }
  .Network__overlay {
    background: rgba(0, 0, 0, 0.9);
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 8rem;
    z-index: 100;
    .title {
      text-align: start;
      color: white;
      margin: 2rem 0 4rem;
    }
    &.--error {
      z-index: 200;
      background: #dc3545;
    }
  }
  .Network__activity {
    color: white;
    display: flex;
    justify-content: space-between;
    max-width: map-get($grid-breakpoints, sm);
    margin: 1rem auto;
    width: 100%;
    h5 {
      color: white;
      max-width: 90%;
    }
    > span.close-button {
      cursor: pointer;
      // border: 3px solid white;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      position: relative;
      &::after,
      &::before {
        content: '';
        height: 100%;
        width: 3px;
        background: white;
        position: absolute;
        transform-origin: center;
      }
      &::after {
        top: 0;
        right: 45%;
        transform: rotate(45deg);
      }
      &::before {
        top: 0;
        left: 45%;
        transform: rotate(-45deg);
      }
    }
  }
}
