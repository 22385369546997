.billing-grid-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(1, auto);
    grid-template-areas: 'tenant date-range' 'buttons buttons';
    table {
        grid-area: tenant;
    }
    .Calendar {
        grid-area: date-range;
    }
    button {
        grid-area: buttons;
    }
}

.Billing__aws-cost-container {
    margin: 1rem 0 5rem 0;
}

.Billing-csv-download {
    display: block;
    margin: 1rem 0;
}