.OnboardingInvitations {
  .invitation-form-container {
    margin: 0 0 2rem 16.6%;
    max-width: 58.3%;
  }

  .invitation-input {
    margin: 1rem 0;
  }

  .invitiation-actions {
    margin: 2rem 0;
    flex-direction: row-reverse;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem 0 0;
  }

  .invitation-container {
    margin: 0 0 2rem 10%;
    max-width: 550px;
  }

  .ant-upload-list-item-name {
    display: none !important;
  }
}
